import React from 'react';
import ReactECharts from 'echarts-for-react';

export default function RealtimeGauge(props) {

    const {
        sensorname,
        value,
        isErr,
        hi,
        low,
        hihi,
        lowlow,
        min,
        max,
        tempType,
        controlLevel,
        updateDatetime
    } = props

    let valueDisplay = value;
    let valueColorDisplay = 'inherit';
    if (isErr === 'Y') {
        valueDisplay = "ERR";
        valueColorDisplay = '#F05524';
    }
    else if (tempType === 'F') {
        valueDisplay = value + "°F";
    } else if (tempType === 'C') {
        valueDisplay = value + "°C";
    }

    let lineStyle = {   // 權限 High，有三色
        color: [
            [(lowlow - min) / (max - min), '#F05524'],
            [(low - min) / (max - min), '#FBCB0A'],
            [(hi - min) / (max - min), '#8CC631'],
            [(hihi - min) / (max - min), '#FBCB0A'],
            [1, '#F05524']
        ]
    }

    if (controlLevel === 'Low') { // 權限 Low，只有兩色
        lineStyle = {
            color: [
                [(low - min) / (max - min), '#F05524'],
                [(hi - min) / (max - min), '#8CC631'],
                [1, '#F05524']
            ]
        }
    }

    const options = {
        series: [{
            type: 'gauge',
            min: min,
            max: max,
            splitNumber: 5,
            axisLine: {
                lineStyle: lineStyle
            },
            pointer: {
                itemStyle: {
                    color: 'inherit'
                }
            },
            axisTick: {
                lineStyle: {
                    color: '#fff',
                    width: 0
                }
            },
            splitLine: {
                distance: -50,
                length: 30,
                lineStyle: {
                    color: '#fff',
                    width: 4
                }
            },
            axisLabel: {
                color: 'inherit',
                distance: 40,
                fontSize: 10
            },
            detail: {
                valueAnimation: true,
                formatter: valueDisplay,
                color: valueColorDisplay
            },
            data: [{
                value: value
            }]
        }]
    };

    let m = new Date(updateDatetime);
    let dateString = m.getFullYear() + "/" + (m.getMonth() + 1) + "/" + m.getDate() + " " + m.getHours() + ":" + m.getMinutes();
    let lightColor = 'green';
    let lightText = '正常';
    let lightPic = '/green.png';
    if (value > hihi || value < lowlow || isErr === 'Y') {
        lightColor = 'red';
        lightText = '異常';
        lightPic = '/red.png';
    }

    return (
        <div style={{ marginTop: 30 }}>
            <h4 style={{ marginLeft: 20 }}>{sensorname}</h4>
            <ReactECharts option={options} />
            <div style={{ width: '100%', textAlign: 'center', marginTop: -72 }}><span style={{ color: lightColor, fontSize: '1.5rem' }}>{lightText}</span><br /><img src={lightPic} /></div>
            <div style={{ width: '100%', textAlign: 'right', marginTop: 10 }}>更新時間:{dateString}</div>
        </div>
    )

}