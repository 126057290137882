import React from 'react';
import { useSelector } from 'react-redux';
import { Form, Row, Col } from 'react-bootstrap';

export default function ParamView() {

    const shadowValue = useSelector(state => state.reducer.wqmsShadow.shadowValue);

    // 檢視參數
    let clientName = ''
    let intervalValue = ''
    let deviceConnectionStatusNotification = false
    let abnormalAlarmNotification = false
    let autoControl = false
    let controlByWaterFlow = false
    let flowThreshold = ''
    let flowMeterUnit = ''
    let tubeSize = ''

    if (shadowValue && shadowValue.state && shadowValue.state.reported) {
        clientName = shadowValue.state.reported.clientName
        if (shadowValue.state.reported.interval) {
            intervalValue = shadowValue.state.reported.interval / 60000
        }
        deviceConnectionStatusNotification = shadowValue.state.reported.deviceConnectionStatusNotification
        abnormalAlarmNotification = shadowValue.state.reported.abnormalAlarmNotification
        autoControl = shadowValue.state.reported.autoControl
        controlByWaterFlow = shadowValue.state.reported.controlByWaterFlow
        flowThreshold = shadowValue.state.reported.flowThreshold
        flowMeterUnit = shadowValue.state.reported.flowMeterUnit
        tubeSize = shadowValue.state.reported.tubeSize
    }

    let s11div = sensorView('s1_1');
    let s12div = sensorView('s1_2');
    let s13div = sensorView('s1_3');
    let s14div = sensorView('s1_4');
    let s15div = sensorView('s1_5');
    let s16div = sensorView('s1_6');

    function sensorView(sensor) {
        let sensor_div = []
        if (shadowValue && shadowValue.state && shadowValue.state.reported
            && shadowValue.state.reported.sensors && shadowValue.state.reported.sensors[sensor]) {
            sensor_div.push(<hr />)
            sensor_div.push(
                <Row>
                    <Col xs={2} sm={2}><span style={{ fontSize: '1.5rem' }}>{shadowValue.state.reported.sensors[sensor].name}</span></Col>
                    <Col xs={10} sm={10}>
                        <Row className="pb-2">
                            <Col>HI: {shadowValue.state.reported.sensors[sensor].HI}</Col>
                            <Col>LOW: {shadowValue.state.reported.sensors[sensor].LOW}</Col>
                        </Row>
                        <Row>
                            <Col>HIHI: {shadowValue.state.reported.sensors[sensor].HIHI}</Col>
                            <Col>LOWLOW: {shadowValue.state.reported.sensors[sensor].LOWLOW}</Col>
                        </Row>
                    </Col>
                </Row>
            )
        }
        return sensor_div
    }

    return (
        <div style={{ padding: 10 }}>
            <Row className="pb-4">
                <Col>客戶名稱： {clientName} </Col>
                <Col>讀取間隔： {intervalValue} 分</Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group id="viewDeviceConnectionStatusNotification">
                        <Form.Check type="checkbox" label="連線狀態通知" checked={deviceConnectionStatusNotification} disabled />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group id="viewAbnormalAlarmNotification">
                        <Form.Check type="checkbox" label="異常警報通知" checked={abnormalAlarmNotification} disabled />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group id="viewAutoControl">
                        <Form.Check type="checkbox" label="自動控制開關" checked={autoControl} disabled />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group id="viewControlByWaterFlow">
                        <Form.Check type="checkbox" label="與流量計連動" checked={controlByWaterFlow} disabled />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>流量閥值： {flowThreshold} {flowMeterUnit}</Col>
                <Col>管徑： {tubeSize} 吋</Col>
            </Row>
            {s11div}
            {s12div}
            {s13div}
            {s14div}
            {s15div}
            {s16div}
        </div>
    )
}