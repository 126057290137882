import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Row, Col, Button, Alert } from 'react-bootstrap';

import {
    genUpdateShadowDesired,
    validateAllSensorParam
} from './utils/util'
import {
    assignFormValue,
    assignFormFloatValue,
    assignFormIntervalValue,
    assignFormSensorValue,
    fetchPubUpdateShadow,
    assignUpdateAlert
} from './slice/wqmsShadowSlice';
import {
    assignAlert
} from './slice/queryHistorySlice';

import {
    assignModelAlert
} from './slice/changePwdSlice';

export default function ParamView() {

    const dispatch = useDispatch();

    const selectedDevice = useSelector(state => state.reducer.wqmsAuth.selectedDevice);
    const shadowValue = useSelector(state => state.reducer.wqmsShadow.shadowValue);
    const formValue = useSelector(state => state.reducer.wqmsShadow.formValue);

    const updateLoading = useSelector(state => state.reducer.wqmsShadow.queryLoading);
    const updateAlertVariant = useSelector(state => state.reducer.wqmsShadow.alertVariant);
    const updateAlertText = useSelector(state => state.reducer.wqmsShadow.alertText);

    // 修改參數
    let deviceId = ''
    let formClientName = ''
    let formIntervalValue = ''
    let formDeviceConnectionStatusNotification = false
    let formAbnormalAlarmNotification = false
    let formAutoControl = false
    let formControlByWaterFlow = false
    let formFlowThreshold = ''
    let formFlowMeterUnit = ''
    let formTubeSize = ''

    if (formValue && formValue.state && formValue.state.reported) {
        formClientName = formValue.state.reported.clientName
        if (formValue.state.reported.interval) {
            formIntervalValue = formValue.state.reported.interval / 60000
        }
        formDeviceConnectionStatusNotification = formValue.state.reported.deviceConnectionStatusNotification
        formAbnormalAlarmNotification = formValue.state.reported.abnormalAlarmNotification
        formAutoControl = formValue.state.reported.autoControl
        formControlByWaterFlow = formValue.state.reported.controlByWaterFlow
        formFlowThreshold = formValue.state.reported.flowThreshold
        formFlowMeterUnit = formValue.state.reported.flowMeterUnit
        formTubeSize = formValue.state.reported.tubeSize
        deviceId = shadowValue.state.reported.deviceId
    }

    let s11_edit_div = sensorEdit('s1_1');
    let s12_edit_div = sensorEdit('s1_2');
    let s13_edit_div = sensorEdit('s1_3');
    let s14_edit_div = sensorEdit('s1_4');
    let s15_edit_div = sensorEdit('s1_5');
    let s16_edit_div = sensorEdit('s1_6');

    function sensorEdit(sensor) {
        let sensor_edit_div = []
        let hiId = "formHI_" + sensor;
        let lowId = "formLOW_" + sensor;
        let hihiId = "formHIHI_" + sensor;
        let lowlowId = "formLOWLOW_" + sensor;
        if (formValue && formValue.state && formValue.state.reported
            && formValue.state.reported.sensors && formValue.state.reported.sensors[sensor]) {
            sensor_edit_div.push(<hr />)
            sensor_edit_div.push(
                <Row>
                    <Col xs={2} sm={2}><span style={{ fontSize: '1.5rem' }}>{formValue.state.reported.sensors[sensor].name}</span></Col>
                    <Col xs={10} sm={10}>
                        <Row className="pb-2">
                            <Col>
                                <Form.Group controlId={hiId}>
                                    <Form.Label>HI</Form.Label>
                                    <Form.Control type="number" value={formValue.state.reported.sensors[sensor].HI} onChange={(event) => {
                                        dispatch(assignFormSensorValue({ sensor: sensor, sensorType: 'HI', value: event.target.value }))
                                    }} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId={lowId}>
                                    <Form.Label>LOW</Form.Label>
                                    <Form.Control type="number" value={formValue.state.reported.sensors[sensor].LOW} onChange={(event) => {
                                        dispatch(assignFormSensorValue({ sensor: sensor, sensorType: 'LOW', value: event.target.value }))
                                    }} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId={hihiId}>
                                    <Form.Label>HIHI</Form.Label>
                                    <Form.Control type="number" value={formValue.state.reported.sensors[sensor].HIHI} onChange={(event) => {
                                        dispatch(assignFormSensorValue({ sensor: sensor, sensorType: 'HIHI', value: event.target.value }))
                                    }} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId={lowlowId}>
                                    <Form.Label>LOWLOW</Form.Label>
                                    <Form.Control type="number" value={formValue.state.reported.sensors[sensor].LOWLOW} onChange={(event) => {
                                        dispatch(assignFormSensorValue({ sensor: sensor, sensorType: 'LOWLOW', value: event.target.value }))
                                    }} />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            )
        }
        return sensor_edit_div;
    }

    async function pubUpdateShadow() {
        if (shadowValue.state.reported.connectedType === 'connected') {
            let errorStr = validateAllSensorParam(formValue.state.reported)
            let ds = genUpdateShadowDesired(shadowValue.state.reported, formValue.state.reported)
            if (errorStr.length > 0) {
                dispatch(assignUpdateAlert({
                    variant: 'danger',
                    text: errorStr
                }));
            } else if (ds[0]) {
                try {
                    console.log('execute pubUpdateShadow', selectedDevice.deviceId);
                    // await PubSub.publish('$aws/things/' + selectedDevice.deviceId + '/shadow/update', ds[1]);
                    dispatch(fetchPubUpdateShadow({ deviceId: selectedDevice.deviceId, updateDesired: ds[1] }));
                } catch (err) {
                    console.log('update shadow err', err);
                }
            } else {
                dispatch(assignUpdateAlert({
                    variant: 'danger',
                    text: '參數未修改'
                }));
            }
        } else {
            dispatch(assignUpdateAlert({
                variant: 'danger',
                text: '未連線無法修改'
            }));
        }
    }

    let updateAlertDisplay = []
    if (updateAlertVariant && updateAlertText) {
        updateAlertDisplay = (
            <Alert variant={updateAlertVariant} onClose={() => clearAlert()} dismissible>
                {updateAlertText}
            </Alert>
        )
    }

    let debugLevel_div = []

    if (formValue.state && formValue.state.reported) {
        debugLevel_div.push(<hr />)
        debugLevel_div.push(
            <Row>
                <Col>
                    <Form.Group controlId="debugLevel">
                        <Form.Label>Debug Level</Form.Label>
                        <div>
                            <Form.Check inline label="DEBUG" name="debugLevel" type='radio' id='DEBUG' checked={formValue.state.reported.debugLevel === 'DEBUG'} onChange={(event) => {
                                dispatch(assignFormValue({ id: 'debugLevel', value: 'DEBUG' }))
                            }} />
                            <Form.Check inline label="WARN" name="debugLevel" type='radio' id='WARN' checked={formValue.state.reported.debugLevel === 'WARN'} onChange={(event) => {
                                dispatch(assignFormValue({ id: 'debugLevel', value: 'WARN' }))
                            }} />
                            <Form.Check inline label="OFF" name="debugLevel" type='radio' id='OFF' checked={formValue.state.reported.debugLevel === 'OFF'} onChange={(event) => {
                                dispatch(assignFormValue({ id: 'debugLevel', value: 'OFF' }))
                            }} />
                        </div>
                    </Form.Group>
                </Col>
            </Row>
        )
    }

    function clearAlert() {
        dispatch(assignAlert({ variant: '', text: '' }));
        dispatch(assignUpdateAlert({ variant: '', text: '' }));
        dispatch(assignModelAlert({ variant: '', text: '' }));
    }

    return (
        <div style={{ padding: 10 }}>
            {updateAlertDisplay}
            <Row className="pb-4">
                <Col>
                    <Button variant="primary" onClick={pubUpdateShadow} disabled={updateLoading}>修改</Button>
                </Col>
                <Col><b>裝置 ID</b>: {deviceId}</Col>
            </Row>
            <Form>
                <Row>
                    <Col>
                        <Form.Group controlId="formClientName">
                            <Form.Label>客戶名稱：</Form.Label>
                            <Form.Control type="text" value={formClientName} onChange={(event) => {
                                dispatch(assignFormValue({ id: 'clientName', value: event.target.value }))
                            }} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="formIntervalValue">
                            <Form.Label>讀取間隔：單位(分)</Form.Label>
                            <Form.Control type="text" value={formIntervalValue} onChange={(event) => {
                                dispatch(assignFormIntervalValue({ value: event.target.value }))
                            }} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group id="formDeviceConnectionStatusNotification">
                            <Form.Check type="checkbox" label="連線狀態通知" checked={formDeviceConnectionStatusNotification} onChange={(event) => {
                                dispatch(assignFormValue({ id: 'deviceConnectionStatusNotification', value: !formDeviceConnectionStatusNotification }))
                            }} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group id="formAbnormalAlarmNotification">
                            <Form.Check type="checkbox" label="異常警報通知" checked={formAbnormalAlarmNotification} onChange={(event) => {
                                dispatch(assignFormValue({ id: 'abnormalAlarmNotification', value: !formAbnormalAlarmNotification }))
                            }} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group id="formAutoControl">
                            <Form.Check type="checkbox" label="自動控制開關" checked={formAutoControl} onChange={(event) => {
                                dispatch(assignFormValue({ id: 'autoControl', value: !formAutoControl }))
                            }} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group id="formControlByWaterFlow">
                            <Form.Check type="checkbox" label="與流量計連動" checked={formControlByWaterFlow} onChange={(event) => {
                                dispatch(assignFormValue({ id: 'controlByWaterFlow', value: !formControlByWaterFlow }))
                            }} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={3} sm={3}>
                        <Form.Group controlId="formFlowThreshold">
                            <Form.Label>流量閥值(值)：</Form.Label>
                            <Form.Control type="number" value={formFlowThreshold} onChange={(event) => {
                                dispatch(assignFormFloatValue({ id: 'flowThreshold', value: event.target.value }))
                            }} />
                        </Form.Group>
                    </Col>
                    <Col xs={3} sm={3}>
                        <Form.Group controlId="formFlowMeterUnit">
                            <Form.Label>流量閥值(單位)</Form.Label>
                            <Form.Control type="text" value={formFlowMeterUnit} onChange={(event) => {
                                dispatch(assignFormValue({ id: 'flowMeterUnit', value: event.target.value }))
                            }} />
                        </Form.Group>
                    </Col>
                    <Col xs={6} sm={6}>
                        <Form.Group controlId="formTubeSize">
                            <Form.Label>管徑：單位(吋)</Form.Label>
                            <Form.Control type="number" value={formTubeSize} onChange={(event) => {
                                dispatch(assignFormFloatValue({ id: 'tubeSize', value: event.target.value }))
                            }} />
                        </Form.Group>
                    </Col>
                </Row>
                {s11_edit_div}
                {s12_edit_div}
                {s13_edit_div}
                {s14_edit_div}
                {s15_edit_div}
                {s16_edit_div}
                {debugLevel_div}
            </Form>
        </div>
    )
}