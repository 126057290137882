import React from 'react';
import { useSelector } from 'react-redux';
import RealtimeGauge from './components/RealtimeGauge';

import '@fortawesome/fontawesome-free/css/all.css'

import {
    getMinMax
} from './utils/util'

function showWaterStatusInner(waterStatus) {
    let desc = (<></>);
    if (waterStatus == 1) {
        desc = (
            <><span style={{ fontSize: '2.6rem', color: '#8CC631' }}><i className="fa fa-solid fa-circle" /></span><span style={{ paddingLeft: 10, verticalAlign: 'bottom' }}>有水</span></>
        )
    } else if (waterStatus == 0) {
        desc = (
            <><span style={{ fontSize: '2.6rem', color: 'black' }}><i className="fa fa-solid fa-circle" /></span><span style={{ paddingLeft: 10, verticalAlign: 'bottom' }}>沒水</span></>
        )
    } else {
        desc = (
            <><span style={{ fontSize: '2.6rem', color: 'grey' }}><i className="fa fa-solid fa-circle" /></span><span style={{ paddingLeft: 10, verticalAlign: 'bottom' }}>未知</span></>
        )
    }
    return (
        <div className="row">
            <div className="col-6"><span style={{ fontSize: '1.4rem' }}>流量開關</span></div>
            <div className="col-6">{desc}</div>
        </div>
    )
}

function showControlStatusInner(controlStatus, controlName) {
    let desc = (<></>);
    if (controlStatus == 1) {
        desc = (
            <><span style={{ fontSize: '2.6rem', color: '#F05524' }}><i className="fa fa-solid fa-circle" /></span><span style={{ paddingLeft: 10, verticalAlign: 'bottom' }}>開</span></>
        )
    } else if (controlStatus == 0) {
        desc = (
            <><span style={{ fontSize: '2.6rem', color: 'grey' }}><i className="fa fa-solid fa-circle" /></span><span style={{ paddingLeft: 10, verticalAlign: 'bottom' }}>關</span></>
        )
    } else {
        desc = (
            <><span style={{ fontSize: '2.6rem', color: '#8CC631' }}><i className="fa fa-solid fa-circle" /></span><span style={{ paddingLeft: 10, verticalAlign: 'bottom' }}>{controlStatus}</span></>
        )
    }
    return (
        <div className="row" style={{ paddingTop: 15 }}>
            <div className="col-6" style={{ paddingLeft: 20, display: 'flex', alignItems: 'center' }}><span style={{ fontSize: '1.2rem' }}>{controlName}</span></div>
            <div className="col-6">{desc}</div>
        </div>
    )
}

export default function RealtimeData() {

    const selectedDevice = useSelector(state => state.reducer.wqmsAuth.selectedDevice);
    const shadowValue = useSelector(state => state.reducer.wqmsShadow.shadowValue);

    let waterStatusInner = [];
    let controlStatusHeader = [];
    let controlStatusInner = [];
    let showControlStatus = false;

    if (shadowValue.state && shadowValue.state.reported) {
        if (shadowValue.state.reported.waterStatus || shadowValue.state.reported.waterStatus == 0) {
            showControlStatus = true;
            waterStatusInner.push(
                showWaterStatusInner(shadowValue.state.reported.waterStatus)
            )
        }
        if (shadowValue.state.reported.controller) {
            if (shadowValue.state.reported.controller.c1_1
                && (shadowValue.state.reported.controller.c1_1.controlStatus || shadowValue.state.reported.controller.c1_1.controlStatus == 0)) {
                showControlStatus = true;
                controlStatusInner.push(
                    showControlStatusInner(shadowValue.state.reported.controller.c1_1.controlStatus, shadowValue.state.reported.controller.c1_1.name)
                )
            }
            if (shadowValue.state.reported.controller.c1_2
                && (shadowValue.state.reported.controller.c1_2.controlStatus || shadowValue.state.reported.controller.c1_2.controlStatus == 0)) {
                showControlStatus = true;
                controlStatusInner.push(
                    showControlStatusInner(shadowValue.state.reported.controller.c1_2.controlStatus, shadowValue.state.reported.controller.c1_2.name)
                )
            }
            if (shadowValue.state.reported.controller.c1_3
                && (shadowValue.state.reported.controller.c1_3.controlStatus || shadowValue.state.reported.controller.c1_3.controlStatus == 0)) {
                showControlStatus = true;
                controlStatusInner.push(
                    showControlStatusInner(shadowValue.state.reported.controller.c1_3.controlStatus, shadowValue.state.reported.controller.c1_3.name)
                )
            }
            if (shadowValue.state.reported.controller.c1_4
                && (shadowValue.state.reported.controller.c1_4.controlStatus || shadowValue.state.reported.controller.c1_4.controlStatus == 0)) {
                showControlStatus = true;
                controlStatusInner.push(
                    showControlStatusInner(shadowValue.state.reported.controller.c1_4.controlStatus, shadowValue.state.reported.controller.c1_4.name)
                )
            }
            if (shadowValue.state.reported.controller.c1_5
                && (shadowValue.state.reported.controller.c1_5.controlStatus || shadowValue.state.reported.controller.c1_5.controlStatus == 0)) {
                showControlStatus = true;
                controlStatusInner.push(
                    showControlStatusInner(shadowValue.state.reported.controller.c1_5.controlStatus, shadowValue.state.reported.controller.c1_5.name)
                )
            }
            if (shadowValue.state.reported.controller.c1_6
                && (shadowValue.state.reported.controller.c1_6.controlStatus || shadowValue.state.reported.controller.c1_6.controlStatus == 0)) {
                showControlStatus = true;
                controlStatusInner.push(
                    showControlStatusInner(shadowValue.state.reported.controller.c1_6.controlStatus, shadowValue.state.reported.controller.c1_6.name)
                )
            }
        }
    }

    let controlStatusDiv = null;
    if (controlStatusInner.length > 0) {
        controlStatusHeader = (
            <div className="row">
                <div className="col-6"><span style={{ fontSize: '1.4rem' }}>控制器</span></div>
                <div className="col-6">&nbsp;</div>
            </div>
        )
    }
    if (showControlStatus) {
        controlStatusDiv = (
            <div className="col-12 col-md-6 col-xl-3" key="controller" style={{ paddingTop: 30 }}>
                {waterStatusInner}
                {controlStatusHeader}
                {controlStatusInner}
            </div>
        )
    }

    let charts = [];
    let querySensorOptions = []
    if (shadowValue.state && shadowValue.state.reported.sensors && shadowValue.state.reported.sensorValue) {
        // S1_1
        if (shadowValue.state.reported.sensors.s1_1 && shadowValue.state.reported.sensorValue.s1_1
            && shadowValue.metadata.reported.sensorValue.s1_1) {
            let minmax = getMinMax({
                sensorType: shadowValue.state.reported.sensors.s1_1.type,
                cdRange: shadowValue.state.reported.CDRange
            });
            charts.push(
                <div className="col-6 col-md-6 col-xl-3" key="s1_1">
                    <RealtimeGauge
                        sensorname={shadowValue.state.reported.sensors.s1_1.name}
                        value={shadowValue.state.reported.sensorValue.s1_1.value}
                        isErr={shadowValue.state.reported.sensorValue.s1_1.isErr}
                        hi={shadowValue.state.reported.sensors.s1_1.HI}
                        low={shadowValue.state.reported.sensors.s1_1.LOW}
                        hihi={shadowValue.state.reported.sensors.s1_1.HIHI}
                        lowlow={shadowValue.state.reported.sensors.s1_1.LOWLOW}
                        min={minmax[0]}
                        max={minmax[1]}
                        controlLevel={selectedDevice.controlLevel}
                        updateDatetime={shadowValue.metadata.reported.sensorValue.s1_1.value.timestamp * 1000}
                    />
                </div>
            )
            querySensorOptions.push(<option value="s1_1" key="s1_1">{shadowValue.state.reported.sensors.s1_1.name}</option>)
        }
        // S1_2
        if (shadowValue.state.reported.sensors.s1_2 && shadowValue.state.reported.sensorValue.s1_2
            && shadowValue.metadata.reported.sensorValue.s1_2) {
            let minmax = getMinMax({
                sensorType: shadowValue.state.reported.sensors.s1_2.type,
                cdRange: shadowValue.state.reported.CDRange
            });
            charts.push(
                <div className="col-6 col-md-6 col-xl-3" key="s1_2">
                    <RealtimeGauge
                        sensorname={shadowValue.state.reported.sensors.s1_2.name}
                        value={shadowValue.state.reported.sensorValue.s1_2.value}
                        isErr={shadowValue.state.reported.sensorValue.s1_2.isErr}
                        hi={shadowValue.state.reported.sensors.s1_2.HI}
                        low={shadowValue.state.reported.sensors.s1_2.LOW}
                        hihi={shadowValue.state.reported.sensors.s1_2.HIHI}
                        lowlow={shadowValue.state.reported.sensors.s1_2.LOWLOW}
                        min={minmax[0]}
                        max={minmax[1]}
                        controlLevel={selectedDevice.controlLevel}
                        updateDatetime={shadowValue.metadata.reported.sensorValue.s1_2.value.timestamp * 1000}
                    />
                </div>
            )
            querySensorOptions.push(<option value="s1_2" key="s1_2">{shadowValue.state.reported.sensors.s1_2.name}</option>)
        }
        // S1_3
        if (shadowValue.state.reported.sensors.s1_3 && shadowValue.state.reported.sensorValue.s1_3
            && shadowValue.metadata.reported.sensorValue.s1_3) {
            let minmax = getMinMax({
                sensorType: shadowValue.state.reported.sensors.s1_3.type,
                cdRange: shadowValue.state.reported.CDRange
            });
            charts.push(
                <div className="col-6 col-md-6 col-xl-3" key="s1_3">
                    <RealtimeGauge
                        sensorname={shadowValue.state.reported.sensors.s1_3.name}
                        value={shadowValue.state.reported.sensorValue.s1_3.value}
                        isErr={shadowValue.state.reported.sensorValue.s1_3.isErr}
                        hi={shadowValue.state.reported.sensors.s1_3.HI}
                        low={shadowValue.state.reported.sensors.s1_3.LOW}
                        hihi={shadowValue.state.reported.sensors.s1_3.HIHI}
                        lowlow={shadowValue.state.reported.sensors.s1_3.LOWLOW}
                        min={minmax[0]}
                        max={minmax[1]}
                        controlLevel={selectedDevice.controlLevel}
                        updateDatetime={shadowValue.metadata.reported.sensorValue.s1_3.value.timestamp * 1000}
                    />
                </div>
            )
            querySensorOptions.push(<option value="s1_3" key="s1_3">{shadowValue.state.reported.sensors.s1_3.name}</option>)
        }
        // S1_4
        if (shadowValue.state.reported.sensors.s1_4 && shadowValue.state.reported.sensorValue.s1_4
            && shadowValue.metadata.reported.sensorValue.s1_4) {
            let minmax = getMinMax({
                sensorType: shadowValue.state.reported.sensors.s1_4.type,
                cdRange: shadowValue.state.reported.CDRange
            });
            charts.push(
                <div className="col-6 col-md-6 col-xl-3" key="s1_4">
                    <RealtimeGauge
                        sensorname={shadowValue.state.reported.sensors.s1_4.name}
                        value={shadowValue.state.reported.sensorValue.s1_4.value}
                        isErr={shadowValue.state.reported.sensorValue.s1_4.isErr}
                        hi={shadowValue.state.reported.sensors.s1_4.HI}
                        low={shadowValue.state.reported.sensors.s1_4.LOW}
                        hihi={shadowValue.state.reported.sensors.s1_4.HIHI}
                        lowlow={shadowValue.state.reported.sensors.s1_4.LOWLOW}
                        min={minmax[0]}
                        max={minmax[1]}
                        controlLevel={selectedDevice.controlLevel}
                        updateDatetime={shadowValue.metadata.reported.sensorValue.s1_4.value.timestamp * 1000}
                    />
                </div>
            )
            querySensorOptions.push(<option value="s1_4" key="s1_4">{shadowValue.state.reported.sensors.s1_4.name}</option>)
        }
        // S1_5
        if (shadowValue.state.reported.sensors.s1_5 && shadowValue.state.reported.sensorValue.s1_5
            && shadowValue.metadata.reported.sensorValue.s1_5) {
            let minmax = getMinMax({
                sensorType: shadowValue.state.reported.sensors.s1_5.type,
                tempType: shadowValue.state.reported.sensorValue.s1_5.tempType,
                cdRange: shadowValue.state.reported.CDRange
            });
            charts.push(
                <div className="col-6 col-md-6 col-xl-3" key="s1_5">
                    <RealtimeGauge
                        sensorname={shadowValue.state.reported.sensors.s1_5.name}
                        value={shadowValue.state.reported.sensorValue.s1_5.value}
                        isErr={shadowValue.state.reported.sensorValue.s1_5.isErr}
                        hi={shadowValue.state.reported.sensors.s1_5.HI}
                        low={shadowValue.state.reported.sensors.s1_5.LOW}
                        hihi={shadowValue.state.reported.sensors.s1_5.HIHI}
                        lowlow={shadowValue.state.reported.sensors.s1_5.LOWLOW}
                        min={minmax[0]}
                        max={minmax[1]}
                        tempType={shadowValue.state.reported.sensorValue.s1_5.tempType}
                        controlLevel={selectedDevice.controlLevel}
                        updateDatetime={shadowValue.metadata.reported.sensorValue.s1_5.value.timestamp * 1000}
                    />
                </div>
            )
            querySensorOptions.push(<option value="s1_5" key="s1_5">{shadowValue.state.reported.sensors.s1_5.name}</option>)
        }
        // S1_6
        if (shadowValue.state.reported.sensors.s1_6 && shadowValue.state.reported.sensorValue.s1_6
            && shadowValue.metadata.reported.sensorValue.s1_6) {
            let minmax = getMinMax({ 
                sensorType: shadowValue.state.reported.sensors.s1_6.type, 
                tempType: shadowValue.state.reported.sensorValue.s1_6.tempType,
                cdRange: shadowValue.state.reported.CDRange 
            });
            charts.push(
                <div className="col-6 col-md-6 col-xl-3" key="s1_6">
                    <RealtimeGauge
                        sensorname={shadowValue.state.reported.sensors.s1_6.name}
                        value={shadowValue.state.reported.sensorValue.s1_6.value}
                        isErr={shadowValue.state.reported.sensorValue.s1_6.isErr}
                        hi={shadowValue.state.reported.sensors.s1_6.HI}
                        low={shadowValue.state.reported.sensors.s1_6.LOW}
                        hihi={shadowValue.state.reported.sensors.s1_6.HIHI}
                        lowlow={shadowValue.state.reported.sensors.s1_6.LOWLOW}
                        min={minmax[0]}
                        max={minmax[1]}
                        tempType={shadowValue.state.reported.sensorValue.s1_6.tempType}
                        controlLevel={selectedDevice.controlLevel}
                        updateDatetime={shadowValue.metadata.reported.sensorValue.s1_6.value.timestamp * 1000}
                    />
                </div>
            )
            querySensorOptions.push(<option value="s1_6" key="s1_6">{shadowValue.state.reported.sensors.s1_6.name}</option>)
        }
        // 流量開關 && 控制
        charts.push(controlStatusDiv);

    } else if (shadowValue.state && shadowValue.state.reported.sensors && !shadowValue.state.reported.sensorValue) {
        charts.push(
            <div style={{ padding: 20 }}>
                <span style={{ fontSize: '1.3rem' }}>無即時資料，可能為舊版 sensor，請洽系統管理員</span>
            </div>
        )
    } else {
        charts.push(
            <div style={{ padding: 20 }}>
                <span style={{ fontSize: '1.3rem' }}>無 sensor 資料，請洽系統管理員</span>
            </div>
        )
    }

    return (
        <div>
            <div className="row">
                {charts}
            </div>
        </div>
    )

}