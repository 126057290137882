import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
    queryWQMSAuths
} from '../api/wqmsAuthAPI'
import {
    sortByDeviceName
} from '../utils/util'

export const fetchQueryWQMSAuths = createAsyncThunk(
    'wqmsAuth/fetchQueryWQMSAuths',
    async (arg, thunkAPI) => {
        return await queryWQMSAuths();
    }
)

export const wqmsAuthSlice = createSlice({
    name: 'wqmsAuth',
    initialState: {
        wqmsAuthList: [],
        selectedDevice: { deviceId: '', deviceName: '', controlLevel: '', updateTime: 0 }
    },
    reducers: {
        assignSelectedDevice: (state, action) => {
            state.selectedDevice = action.payload;
        },
        clearSelectedDevice: state => {
            state.selectedDevice = { deviceId: '', deviceName: '', controlLevel: '', updateTime: 0 }
        }
    },
    extraReducers: {
        [fetchQueryWQMSAuths.fulfilled]: (state, action) => {
            state.wqmsAuthList = sortByDeviceName(action.payload.queryWQMSAuthProds.items)
        }
    }
});

export const { assignSelectedDevice, clearSelectedDevice } = wqmsAuthSlice.actions;

export default wqmsAuthSlice.reducer;