export function getMinMax(bean) {
    let min = 0
    let max = 0
    let sensorType = bean.sensorType;
    let tempType = bean.tempType;
    let cdRange = bean.cdRange;

    if (sensorType === 'pH') {
        min = 0;
        max = 14;
    }
    else if (sensorType === 'ORP') {
        min = -1000;
        max = 1000;
    }
    else if (sensorType === 'CD') {
        min = 0;
        if (cdRange) {
            max = cdRange;
        } else {
            max = 2000;
        }
    }
    else if (sensorType === 'Temp') {
        if (tempType) {
            if (tempType === 'F') {
                min = 0;
                max = 120;
            } else {
                min = -20;
                max = 50;
            }
        } else {
            min = -20;
            max = 120;
        }
    }
    else if (sensorType === 'FLA') {
        min = 0;
        max = 200;
    }
    return [min, max]
}

export function genUpdateShadowDesired(orgShadowValue, formValue) {
    let changed = false;
    let diff = { "state": { "desired": { "sensors": {} } } };
    let ds = [changed, diff]

    // column
    ds = diffValue(orgShadowValue, formValue, 'clientName', ds[0], ds[1])
    ds = diffValue(orgShadowValue, formValue, 'interval', ds[0], ds[1])
    ds = diffValue(orgShadowValue, formValue, 'deviceConnectionStatusNotification', ds[0], ds[1])
    ds = diffValue(orgShadowValue, formValue, 'abnormalAlarmNotification', ds[0], ds[1])
    ds = diffValue(orgShadowValue, formValue, 'autoControl', ds[0], ds[1])
    ds = diffValue(orgShadowValue, formValue, 'controlByWaterFlow', ds[0], ds[1])
    ds = diffValue(orgShadowValue, formValue, 'flowThreshold', ds[0], ds[1])
    ds = diffValue(orgShadowValue, formValue, 'flowMeterUnit', ds[0], ds[1])
    ds = diffValue(orgShadowValue, formValue, 'tubeSize', ds[0], ds[1])

    // sensor hi, low, hihi, lowlow
    ds = diffSensor(orgShadowValue, formValue, 's1_1', ds[0], ds[1])
    ds = diffSensor(orgShadowValue, formValue, 's1_2', ds[0], ds[1])
    ds = diffSensor(orgShadowValue, formValue, 's1_3', ds[0], ds[1])
    ds = diffSensor(orgShadowValue, formValue, 's1_4', ds[0], ds[1])
    ds = diffSensor(orgShadowValue, formValue, 's1_5', ds[0], ds[1])
    ds = diffSensor(orgShadowValue, formValue, 's1_6', ds[0], ds[1])

    // debugLevl
    ds = diffValue(orgShadowValue, formValue, 'debugLevel', ds[0], ds[1])

    // console.log(ds[1])
    return ds
}

function diffValue(orgShadowValue, formValue, columnName, changed, diff) {
    if (orgShadowValue[columnName] != formValue[columnName]) {
        changed = true;
        diff.state.desired[columnName] = formValue[columnName]
    }
    return [changed, diff]
}

function diffSensor(orgShadowValue, formValue, sensor, changed, diff) {
    if (orgShadowValue.sensors[sensor] && formValue.sensors[sensor]) {
        diff.state.desired.sensors[sensor] = {}
        if (orgShadowValue.sensors[sensor].HI != formValue.sensors[sensor].HI) {
            changed = true;
            diff.state.desired.sensors[sensor].HI = formValue.sensors[sensor].HI
        }
        if (orgShadowValue.sensors[sensor].LOW != formValue.sensors[sensor].LOW) {
            changed = true;
            diff.state.desired.sensors[sensor].LOW = formValue.sensors[sensor].LOW
        }
        if (orgShadowValue.sensors[sensor].HIHI != formValue.sensors[sensor].HIHI) {
            changed = true;
            diff.state.desired.sensors[sensor].HIHI = formValue.sensors[sensor].HIHI
        }
        if (orgShadowValue.sensors[sensor].LOWLOW != formValue.sensors[sensor].LOWLOW) {
            changed = true;
            diff.state.desired.sensors[sensor].LOWLOW = formValue.sensors[sensor].LOWLOW
        }
    }
    return [changed, diff]
}

export function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

export function getDayStr(date) {
    return date.getFullYear() + "-"
        + ("0" + (date.getMonth() + 1)).slice(-2) + "-"
        + ("0" + date.getDate()).slice(-2)
}

export function checkDeviceInAuth(authList, deviceId) {
    if (authList.length === 0) {
        return true;
    }
    for (const device of authList) {
        if (device.deviceId === deviceId) {
            return true;
        }
    }
    return false;
}

function validateSensorParam(sensors, sensorValue, cdRange) {
    let errorStr = "";
    if (sensors && sensors.type) {
        let minmax = getMinMax({ sensorType: sensors.type, cdRange: cdRange });
        if (sensorValue) {
            minmax = getMinMax({ sensorType: sensors.type, tempType: sensorValue.tempType, cdRange: cdRange });
        }
        let min = minmax[0]
        let max = minmax[1]

        if (+sensors.HIHI > +max || +sensors.LOWLOW < +min) {
            errorStr = errorStr.concat(sensors.type, ' HIHI,LOWLOW設定超過範圍; ');
        }
        if (+sensors.HIHI < +sensors.HI || +sensors.HI < +sensors.LOW || +sensors.LOW < +sensors.LOWLOW) {
            errorStr = errorStr.concat(sensors.type, ' HIHI,LOWLOW值順序有誤; ');
        }
    }
    return errorStr;
}

export function validateAllSensorParam(formValue) {
    let errorStr = "";
    errorStr = errorStr.concat(validateSensorParam(formValue.sensors.s1_1, formValue.sensorValue.s1_1, formValue.CDRange));
    errorStr = errorStr.concat(validateSensorParam(formValue.sensors.s1_2, formValue.sensorValue.s1_2, formValue.CDRange));
    errorStr = errorStr.concat(validateSensorParam(formValue.sensors.s1_3, formValue.sensorValue.s1_3, formValue.CDRange));
    errorStr = errorStr.concat(validateSensorParam(formValue.sensors.s1_4, formValue.sensorValue.s1_4, formValue.CDRange));
    errorStr = errorStr.concat(validateSensorParam(formValue.sensors.s1_5, formValue.sensorValue.s1_5, formValue.CDRange));
    errorStr = errorStr.concat(validateSensorParam(formValue.sensors.s1_6, formValue.sensorValue.s1_6, formValue.CDRange));
    return errorStr;
}

export function findDeviceAuthById(deviceId, wqmsAuthList) {
    let result = {};
    for (const wqmsAuth of wqmsAuthList) {
        if (wqmsAuth.deviceId === deviceId) {
            result = wqmsAuth;
            break;
        }
    }
    return result;
}

export function sortByDeviceName(arr) {
    return arr.sort((a, b) => (a.deviceName > b.deviceName) ? 1 : ((b.deviceName > a.deviceName) ? -1 : 0));
}