import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Amplify, { Auth, PubSub } from 'aws-amplify'
import { AWSIoTProvider } from '@aws-amplify/pubsub';
import { Form, Row, Col, Button, Tabs, Tab, Alert, Modal } from 'react-bootstrap';
import RealtimeData from './RealtimeData';
import HistoryData from './HistoryData';
import ParamView from './ParamView';
import ParamEdit from './ParamEdit';

import './App.css';
import { withAuthenticator } from '@aws-amplify/ui-react'
import { Authenticator } from '@aws-amplify/ui-react';
import 'admin-lte/dist/css/adminlte.css'
import '@fortawesome/fontawesome-free/css/all.css'
import 'admin-lte/plugins/jquery/jquery.min.js'
import 'admin-lte/plugins/bootstrap/js/bootstrap.bundle.min.js'
import 'admin-lte/dist/js/adminlte.min.js'

import {
  checkDeviceInAuth,
  findDeviceAuthById
} from './utils/util'

import {
  assignSelectedDevice,
  fetchQueryWQMSAuths
} from './slice/wqmsAuthSlice';
import {
  assignShadowValue,
  fetchPubGetShadow,
  assignUpdateAlert,
  assignOpacity
} from './slice/wqmsShadowSlice';
import {
  assignAlert,
  clearQueryData
} from './slice/queryHistorySlice';
import {
  handleShowPwdModel,
  handleClosePwdModel,
  assignOldPwd,
  assignNewPwd,
  assignConfirmPwd,
  assignModelAlert,
  fetchChangePwd
} from './slice/changePwdSlice';

function App() {

  const dispatch = useDispatch();

  const wqmsAuthList = useSelector(state => state.reducer.wqmsAuth.wqmsAuthList);
  const selectedDevice = useSelector(state => state.reducer.wqmsAuth.selectedDevice);
  const shadowValue = useSelector(state => state.reducer.wqmsShadow.shadowValue);
  const opacity = useSelector(state => state.reducer.wqmsShadow.opacity);

  const [key, setKey] = useState('tab1');
  const [useDrawer, setUseDrawer] = useState(false);
  const [identityId, setIdentityId] = useState('');

  const showModel = useSelector(state => state.reducer.changePwd.showModel);
  const oldPwd = useSelector(state => state.reducer.changePwd.oldPwd);
  const newPwd = useSelector(state => state.reducer.changePwd.newPwd);
  const confirmPwd = useSelector(state => state.reducer.changePwd.confirmPwd);
  const modelAlertVariant = useSelector(state => state.reducer.changePwd.alertVariant);
  const modelAlertText = useSelector(state => state.reducer.changePwd.alertText);

  let checkExec;
  let subGetShadowCompleted;
  let refreshIntervalId;
  let subObject;
  let subObject2;

  useEffect(() => {
    dispatch(clearQueryData());
    dispatch(fetchQueryWQMSAuths());
    clearAlert();
    gotCognitoIdentityId();
    addPluggable();
    let orgSelectedDeviceId = localStorage.getItem('selectedDeviceId');
    let orgSelectedTab = localStorage.getItem('selectedTab');
    if (orgSelectedDeviceId) {
      dispatch(assignSelectedDevice({ deviceId: orgSelectedDeviceId, updateTime: 0 }));
      if (orgSelectedTab) {
        setKey(orgSelectedTab)
        localStorage.setItem('selectedTab', orgSelectedTab)
      } else {
        setKey('tab1')
        localStorage.setItem('selectedTab', 'tab1')
      }
    }
    setUseDrawer(window.matchMedia("(max-width: 1004px)").matches);
    return () => {
      if (subObject) {
        subObject.unsubscribe();
        console.log('unsub in useEffect:' + selectedDevice.deviceId);
      }
      if (subObject2) {
        subObject2.unsubscribe();
      }
    };
  }, []);

  useEffect(() => {
    dispatch(clearQueryData());
    if (selectedDevice.updateTime == 0) {
      clearAlert();
    }
    if (selectedDevice.deviceId) {
      dispatch(assignOpacity(0.5));
      subGetShadowCompleted = "N";
      subObject = subGetShadow(selectedDevice.deviceId);
      console.log('sub:' + selectedDevice.deviceId);
      checkExec = "N";
      refreshIntervalId = setInterval(function () { pubGetShadow(selectedDevice.deviceId); }, 2000);

      subObject2 = subUpdateShadow(selectedDevice.deviceId);
    }
    return () => {
      if (selectedDevice.deviceId) {
        if (subObject) {
          subObject.unsubscribe();
        }
        if (subObject2) {
          subObject2.unsubscribe();
        }
        clearInterval(refreshIntervalId);
        console.log('unsub:' + selectedDevice.deviceId);
      }
    };
  }, [selectedDevice]);

  useEffect(() => {
    if (selectedDevice.deviceId) {
      // 權限不符
      if (!checkDeviceInAuth(wqmsAuthList, selectedDevice.deviceId)) {
        dispatch(assignSelectedDevice({ deviceId: '', deviceName: '', controlLevel: '', updateTime: 0 }));
        dispatch(assignOpacity(1));
      }
      // 取得 deviceName, controlLevel
      let wqmsAuth = findDeviceAuthById(selectedDevice.deviceId, wqmsAuthList);
      dispatch(assignSelectedDevice({ deviceId: selectedDevice.deviceId, deviceName: wqmsAuth.deviceName, controlLevel: wqmsAuth.controlLevel, updateTime: 0 }));
    }
  }, [wqmsAuthList]);

  const assignDevice = (device) => {
    localStorage.setItem('selectedDeviceId', device.deviceId)
    dispatch(assignSelectedDevice({
      deviceId: device.deviceId,
      deviceName: device.deviceName,
      controlLevel: device.controlLevel,
      updateTime: 0
    }));

    let selectedTab = localStorage.getItem('selectedTab');
    if (selectedTab == 'tab3' && device.controlLevel == 'High') {
      selectTab('tab4')
    }
    if (selectedTab == 'tab4' && device.controlLevel == 'Low') {
      selectTab('tab3')
    }
  }

  const refreshDevice = () => {
    dispatch(assignSelectedDevice({
      deviceId: selectedDevice.deviceId,
      deviceName: selectedDevice.deviceName,
      controlLevel: selectedDevice.controlLevel,
      updateTime: 0
    }));
  }

  function gotCognitoIdentityId() {
    Auth.currentCredentials().then((info) => {
      // console.log(info);
      if (info.identityId) {
        setIdentityId(info.identityId);
      }
    });
  }

  function addPluggable() {
    // Apply plugin with configuration
    Amplify.addPluggable(new AWSIoTProvider({
      aws_pubsub_region: 'us-west-2',
      aws_pubsub_endpoint: 'wss://a36zsyic97m9r4-ats.iot.us-west-2.amazonaws.com/mqtt'
    }));
  }

  function subGetShadow(deviceId) {
    return PubSub.subscribe('$aws/things/' + deviceId + '/shadow/get/accepted').subscribe({
      next: data => {
        // console.log('get message received', data.value);
        console.log('get message received');
        // if (data.value && data.value.state && data.value.state.reported) {
        //   console.log(data.value.state.reported);
        // }
        dispatch(assignShadowValue(data.value));
        subGetShadowCompleted = "Y";
      },
      error: error => console.error(error),
      close: () => console.log('Done'),
    });
  }

  async function pubGetShadow(deviceId) {
    try {
      if (checkExec === "N" || subGetShadowCompleted === "N") {
        console.log('execute pubGetShadow', deviceId);
        // await PubSub.publish('$aws/things/' + deviceId + '/shadow/get', {});
        dispatch(fetchPubGetShadow({ deviceId: deviceId }));
        checkExec = "Y"
        clearInterval(refreshIntervalId);
        // console.log('end publish:' + checkExec);
      }
    } catch (err) {
      console.log('pub topic err', err);
    }
  }

  function subUpdateShadow(deviceId) {
    return PubSub.subscribe('$aws/things/' + deviceId + '/shadow/update/accepted').subscribe({
      next: data => {
        console.log('update message received', data.value);
        let datenow = Date.now();
        dispatch(assignSelectedDevice({
          deviceId: selectedDevice.deviceId,
          deviceName: selectedDevice.deviceName,
          controlLevel: selectedDevice.controlLevel,
          updateTime: datenow
        }));
      },
      error: error => console.error(error),
      close: () => console.log('Done'),
    });
  }

  function execChangePwd() {
    if (newPwd === confirmPwd) {
      dispatch(fetchChangePwd({
        oldPwd: oldPwd,
        newPwd: newPwd
      }));
    } else {
      dispatch(assignModelAlert({
        variant: 'danger',
        text: '新密碼和確認密碼不一致'
      }));
    }
  }

  function closeModel() {
    dispatch(handleClosePwdModel());
    dispatch(assignModelAlert({
      variant: '',
      text: ''
    }));
  }

  let menuItems = [];
  let pushmenu = (useDrawer) ? "pushmenu" : "";
  for (let i = 0; i < wqmsAuthList.length; i++) {
    menuItems.push(
      <li className="nav-item" key={i}>
        <a href="#" className="nav-link" onClick={() => assignDevice(wqmsAuthList[i])} data-widget={pushmenu}>
          <i className="far fa-circle nav-icon"></i>
          <p>{wqmsAuthList[i].deviceName}</p>
        </a>
      </li>
    );
  }

  let modelAlertDisplay = []
  if (modelAlertVariant && modelAlertText) {
    modelAlertDisplay = (
      <Alert variant={modelAlertVariant} onClose={() => clearAlert()} dismissible>
        {modelAlertText}
      </Alert>
    )
  }

  function clearAlert() {
    dispatch(assignAlert({ variant: '', text: '' }));
    dispatch(assignUpdateAlert({ variant: '', text: '' }));
    dispatch(assignModelAlert({ variant: '', text: '' }));
  }

  let light = []
  if (shadowValue.state && shadowValue.state.reported && shadowValue.state.reported.connectedType) {
    if (shadowValue.state.reported.connectedType == 'connected') {
      light = (
        <span>
          <img src="/green_light.png" />  已連線
        </span>
      )
    } else if (shadowValue.state.reported.connectedType == 'disconnected') {
      light = (
        <span>
          <img src="/red_light.png" />  連線失敗
        </span>
      )
    } else {
      light = (
        <span>
          <img src="/red_light.png" />  未知狀態
        </span>
      )
    }
  } else {
    light = (
      <span>
        <img src="/red_light.png" />  嘗試連線中
      </span>
    )
  }

  let currentYear = new Date().getFullYear();

  // Tab3 檢視參數
  let tab3 = []
  if (selectedDevice.controlLevel == 'Low') {
    tab3 = (
      <Tab eventKey="tab3" title="檢視參數">
        <ParamView />
      </Tab>
    )
  }
  // Tab4 修改參數
  let tab4 = []
  if (selectedDevice.controlLevel == 'High') {
    tab4 = (
      <Tab eventKey="tab4" title="修改參數">
        <ParamEdit />
      </Tab>
    )
  }

  function selectTab(key) {
    setKey(key);
    localStorage.setItem('selectedTab', key);
    clearAlert()
  }

  let content = []
  if (wqmsAuthList.length == 0) {
    content = (
      <div className="container-fluid" style={{ padding: 20 }}>
        <span style={{ fontSize: '1.3rem' }}>無可使用裝置，請洽系統管理員</span>
      </div>
    )
  } else if (!selectedDevice.deviceId) {
    content = (
      <div className="container-fluid" style={{ padding: 20 }}>
        <span style={{ fontSize: '1.3rem' }}>請先至 menu 選擇使用裝置</span>
      </div>
    )
  } else {
    content = (
      <div className="container-fluid">
        <div style={{ padding: 10, marginBottom: 16 }}>
          <span style={{ fontSize: '1.5rem' }}>裝置: {selectedDevice.deviceName}</span>
          <span style={{ float: 'right' }}>
            <a href="#" onClick={refreshDevice} data-toggle="tooltip" title="重新整理"><i className="fas fa-sync-alt"></i></a>
            &nbsp;&nbsp;&nbsp;&nbsp;連線狀態：{light}
          </span>
        </div>
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => selectTab(k)}
        >
          <Tab eventKey="tab1" title="即時資料">
            <RealtimeData
            />
          </Tab>
          <Tab eventKey="tab2" title="歷史資料">
            <HistoryData
            />
          </Tab>
          {tab3}
          {tab4}
        </Tabs>
      </div>
    )
  }

  return (
    <div className="wrapper" style={{ opacity: opacity }}>
      <Authenticator>
        {({ signOut, user }) => (
          <nav className="main-header navbar navbar-expand navbar-white navbar-light">
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars"></i></a>
              </li>
            </ul>
            <ul className="navbar-nav ml-auto">
              <li className="nav-item dropdown">
                <a className="nav-link" data-toggle="dropdown" href="#">
                  {user.username}
                </a>
                <div className="dropdown-menu dropdown-menu-right">
                  <a href="#" onClick={(event) => {
                    dispatch(handleShowPwdModel())
                  }} className="dropdown-item dropdown-footer">更換密碼</a>
                  <a href="#" onClick={signOut} className="dropdown-item dropdown-footer">登出</a>
                </div>
              </li>
            </ul>
          </nav>
        )}
      </Authenticator>

      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        {/* <!-- Brand Logo --> */}
        <a href="#" className="brand-link">
          <img src="logo_192.jpg" alt="Logo" className="brand-image elevation-3" />
          <span className="brand-text">Trakker雲端監控系統</span>
        </a>

        {/* <!-- Sidebar --> */}
        <div className="sidebar">

          {/* <!-- Sidebar Menu --> */}
          <nav className="mt-2">
            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu">
              <li className="nav-item menu-open">
                <ul className="nav nav-treeview">
                  {menuItems}
                </ul>
              </li>
            </ul>
          </nav>
          {/* <!-- /.sidebar-menu --> */}
        </div>
        {/* <!-- /.sidebar --> */}
      </aside>

      <div className="content-wrapper">
        <section className="content">
          {content}
        </section>
      </div>

      <footer className="main-footer">
        <div className="float-right d-none d-sm-block"></div>
        <strong>Copyright &copy; {currentYear} TaiwanKK.</strong> All rights reserved.
      </footer>

      <aside className="control-sidebar control-sidebar-dark">
      </aside>

      <Modal show={showModel} onHide={closeModel}>
        <Modal.Header closeButton>
          <Modal.Title>修改密碼</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modelAlertDisplay}
          <Row>
            <Col>
              <Form.Group controlId="oldPwd">
                <Form.Label>舊密碼</Form.Label>
                <Form.Control type="password" onChange={(event) => {
                  dispatch(assignOldPwd(event.target.value))
                }} />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="newPwd">
                <Form.Label>新密碼</Form.Label>
                <Form.Control type="password" onChange={(event) => {
                  dispatch(assignNewPwd(event.target.value))
                }} />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="confirmPwd">
                <Form.Label>確認新密碼</Form.Label>
                <Form.Control type="password" onChange={(event) => {
                  dispatch(assignConfirmPwd(event.target.value))
                }} />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={execChangePwd} block>
            更換密碼
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  );
}

export default withAuthenticator(App)